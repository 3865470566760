<template>
  <v-dialog v-model="dialog" persistent max-width="450px">
    <v-card grid-list-md>
      <ModelTitle title="Edit Department" @close="close()" />
       <v-card-text>
        <v-row>
          <v-col cols="12" sm="12">
            <v-text-field
              outlined
              :hide-details="nameErrors.length === 0"
              dense
              @keyup.enter="submit"
              :error-messages="nameErrors"
              v-model="Department.name"
              label="Name"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="close()">Close</v-btn>
        <v-btn color="pink lighten-1" :loading="loading" dark @click="submit()"
          >Update</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import DepartmentService from "../service.js";
import _ from "lodash";

const { validationMixin } = require("vuelidate");
const { required } = require("vuelidate/lib/validators");

export default {
  props: {
    editDialog: {
      default: false
    },
    editDepartment: {
      type: Object
    }
  },
  name: "update-Department",
  data() {
    return {
      loading: false,
      dialog: false,
      Department: {
        name: null
      }
    };
  },
  mixins: [validationMixin],
  validations: {
    Department: {
      name: {
        required
      }
    }
  },
  computed: {
    nameErrors() {
      const errors = [];
      if (!this.$v.Department.name.$dirty) return errors;
      if (!this.$v.Department.name.required)
        errors.push(this.$t("validations.fieldIsRequired"));

      return errors;
    }
  },
  watch: {
    editDialog(val) {
      this.Department = _.clone(this.editDepartment)
      this.dialog = val;
    }
  },
  methods: {
    close() {
      this.empty();
      this.$emit("close");
    },
    empty() {
      this.Department = {
        name: null,
      };
      this.$v.$reset();
    },
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$swal
          .mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000
          })
          .fire({
            icon: "error",
            title: this.$t("toastMessage.text.invalidFields")
          });
      } else {
        this.loading = true;
        this.Department.name = this.Department.name.charAt(0).toUpperCase() + this.Department.name.slice(1)
        return DepartmentService.update(this.Department.id, this.Department)
          .then(result => {
            if (result.status) {
              this.$swal
                .mixin({
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000
                })
                .fire({
                  icon: "success",
                  title: "Department is updated"
                });
              this.loading = false;
              this.$emit("submit");
              this.close();
            }
          })
          .catch(err => {
            this.loading = false;
            this.$swal
              .mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000
              })
              .fire({
                icon: "error",
                title: err.data.message
              });
          });
      }
    }
  }
};
</script>

<style scoped></style>
